<template>
  <div>
    <div class="barra"></div>
    <div class="row justify-content-center">
      <div class="col- cl col-sm-12 col-md-6 col-lg-5 col-xl-4">
        <div class="card cardl text-center border-primary mb-3">
          <div class="card-header">
            <h5>Cambiar contraseña</h5>
          </div>
          <div class="card-body">
            <form method="POST" action ref="form">
              <div class="form-group">
                <input
                  placeholder="Correo electronico"
                  type="email"
                  name="email"
                  class="form-control"
                  v-model="email"
                  required
                />
              </div>

              <div class="form-group">
                <input
                  placeholder="Nueva contraseña"
                  id="password"
                  type="password"
                  name="password"
                  class="form-control"
                  v-model="password"
                  hint="At least 8 characters"
                  required
                  v-on:keyup.enter="login"
                />
              </div>
              <div class="alert alert-warning" v-if="res" role="alert">
                {{ res }}
              </div>
              <div class="alert alert-warning" v-if="res2" role="alert">
                <div v-for="alert in res2" :key="alert.id">
                  {{ alert[0] }}
                </div>
              </div>
              <div class="form-group row">
                <div class="col">
                  <div>
                    <button
                      id="savePassword"
                      class="btn btn-primary btn-block btnsend"
                      @click="savePassword"
                    >
                      Guardar
                    </button>
                    <router-link to="/login" class="btn btn-link btn-block" id="login"
                      >Inicia sesión</router-link
                    >
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="card-footer text-muted">
            ¿Aún no tienes cuenta?
            <router-link to="/register">Registrate aquí</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "showResetPassword",
  components: {},
  data() {
    return {
      email: "",
      token: "",
      password: "",
      res: "",
      res2: "",
    };
  },
  created() {
    this.getToken();
  },
  mounted() {},
  methods: {
    getToken() {
      this.token = this.$router.currentRoute.params.url;
    },
    savePassword(e) {
      e.preventDefault();
      this.res = "";
      this.res2 = "";
      const url = this.appDomainApi1 + `email/reset-password`;
      const params = {
        token: this.token,
        email: this.email,
        password: this.password,
      };
      if (this.email && this.password && this.token) {
        axios
          .post(url, params)
          .then((res) => {
            this.res = res.data.message;
            var savePassword = document.querySelector("#savePassword");
            var login = document.querySelector("#login");
            savePassword.style.display = "none";
            login.classList.remove("btn-link");
            login.classList.add("btn-primary");
          })
          .catch((err) => {
            console.error(err);
            this.res2 = err.response.data.errors;
          });
      } else {
        this.res = "Email o contraseña invalido";
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
.barra {
  height: 100px;
}
.imgav {
  display: flex;
  align-content: center;
  justify-content: center;
}
.cAvatar {
  position: relative;
  width: 150px;
  height: 150px;
  background-color: white;
  border-radius: 50%;
  overflow: hidden;
}
.cImage {
  position: absolute;
  width: 150px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
</style>
